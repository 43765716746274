import React, { } from 'react';

import { Routes, Route  } from "react-router-dom";

import { Home } from "../pages/Home/Home.jsx";
import { Politica } from '../pages/Politica/Politica.jsx';

export function AppRoutes(){


    return(
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/politicaprivacidade" element={<Politica/>} />
        </Routes>
    );

}