import styled from "styled-components";

import Banner from "../../assets/banner-inicial.jpg"
import { Grid } from "@mui/material";
import Imagem3 from "../../assets/sessao2-elemento.png"

// Função para inicializar o SVG
function initSVG() {
  
    // Seleciona o SVG
    const svgCanvas = document.getElementById("svgCanvas");
  
    // Define grupos de pontos para diferentes linhas
    const groups = [
      { class: ".line1", points: [".A", ".B", ".C"], text: "Sustentável" },
      { class: ".line2", points: [".D", ".E", ".F"], text: "Eficiente" },
      { class: ".line3", points: [".G", ".H", ".I"], text: "Econômico" },
      { class: ".line4", points: [".J", ".K", ".L"], text: "Durável" },
    ];
  
    // Cria o IntersectionObserver para observar o svgCanvas
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
               //     console.log("NA TELA")
            // Inicia a animação das linhas quando o SVG está visível
          groups.forEach((group) => {
              animateLine(svgCanvas, group.class, group.points, group.text);
            });
 
            // Desconecta o observer após a animação ser iniciada
            observer.disconnect();
          }else{
           // console.log("NAO TELA")
          }
        });
      },
      {
        threshold: 0.5, // Define quanto do elemento precisa estar visível (10% neste caso)
      }
    );
  
    // Observa o svgCanvas
    observer.observe(svgCanvas);
  }
  
  // Função para animar cada linha
  function animateLine(svgCanvas, lineClass, pointClasses, startText) {
    // Seleciona os elementos invisíveis
    const elements = document.querySelectorAll(pointClasses.join(", "));
    const polyline = svgCanvas.querySelector(lineClass);
  
    // Verifica se todos os elementos foram localizados
    if (svgCanvas && elements.length > 0 && polyline) {
      // Obtém as coordenadas dos elementos
      const svgRect = svgCanvas.getBoundingClientRect();
      const points = Array.from(elements).map((element) => {
        const { x, y } = element.getBoundingClientRect();
        return { x: x - svgRect.x, y: y - svgRect.y };
      });
  
// Define os pontos do polyline
const pointsString = points.map((p) => `${p.x},${p.y}`).join(" ");

// Define os pontos e os atributos no elemento polyline
polyline.setAttribute("points", pointsString);
polyline.setAttribute("stroke", "#43A7A6");
polyline.setAttribute("stroke-width", "2.4");
polyline.setAttribute("stroke-dasharray", "100%");

// Inicia a animação
requestAnimationFrame(() => {
  polyline.style.animation = "dash 2s linear forwards";
});

  
      // Usar setTimeout para garantir que o círculo final seja adicionado
      setTimeout(() => {
        addCircles(svgCanvas, points);
      }, 1200); // Usar o mesmo tempo da transição (2 segundos)
  
      // Adiciona um círculo sólido no início
      const startCircle = createCircle(points[0].x, points[0].y, 5, "#43A7A6");
      svgCanvas.appendChild(startCircle);
  
      // Adiciona texto no início
      addText(svgCanvas, startText, points[0].x, points[0].y, 100);
  
    } else {
      console.log("ELEMENTO NAO LOCALIZADO");
    }
  }
  
  // Função para criar círculos
  function createCircle(cx, cy, radius, color) {
    const circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
    circle.setAttribute("cx", cx);
    circle.setAttribute("cy", cy);
    circle.setAttribute("r", radius);
    circle.setAttribute("fill", color);
    return circle;
  }
  
  // Função para adicionar círculos
  function addCircles(svgCanvas, points) {
  
    points.forEach((point, index) => {
      if (index === points.length - 1) {
        // Círculo animado no final
        const endCircle = createCircle(point.x, point.y, 5, "#43A7A6");
        const animate = createBlinkingAnimation();
        endCircle.appendChild(animate);
        svgCanvas.appendChild(endCircle);
      } else {
        // Círculos sólidos nos pontos intermediários
        // const intermediateCircle = createCircle(point.x, point.y, 3, "#F39C12");
        // svgCanvas.appendChild(intermediateCircle);
      }
    });
  }
  
  // Função para criar animação de piscar
  function createBlinkingAnimation() {
    const animate = document.createElementNS("http://www.w3.org/2000/svg", "animate");
    animate.setAttribute("attributeType", "XML");
    animate.setAttribute("attributeName", "opacity");
    animate.setAttribute("values", "1;0;1");
    animate.setAttribute("dur", "1s");
    animate.setAttribute("repeatCount", "indefinite");
    return animate;
  }
  
  // Função para adicionar texto com animação de digitação
  function addText(svgCanvas, text, startX, startY, delay) {
    const textElement = document.createElementNS("http://www.w3.org/2000/svg", "text");
    textElement.setAttribute("x", startX + 10);
    textElement.setAttribute("y", startY - 10);
    textElement.setAttribute("fill", "#214761");
    textElement.setAttribute("font-family", "Baloo");
    textElement.setAttribute("style", "font-size: 3rem");
    svgCanvas.appendChild(textElement);
  
    let typedText = "";
    let index = 0;
    const typingInterval = setInterval(() => {
      typedText += text[index];
      textElement.textContent = typedText;
      index++;
      if (index >= text.length) {
        clearInterval(typingInterval);
      }
    }, delay);
  }
  
  document.addEventListener("DOMContentLoaded", initSVG);

  export const AnimationContainer = styled.div`
  position: absolute;
  background-image: url(${Imagem3});
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 500px 300px;
  right: 0;
  width: 700px;
  height: 500px;

  #svgCanvas {
    max-width: 100%;
  }

  @keyframes dash {
    from {
      stroke-dashoffset: 100%;
    }
    to {
      stroke-dashoffset: 0%;
    }
  }
  

  .point {
    position: absolute;
  }

  .A {
    left: 2rem;
    top: 5.5rem;
  }

  .B {
    left: 26rem;
    top: 5.5rem;
  }

  .C {
    left: 35rem;
    top: 9rem;
  }

  .D {
    left: 1rem;
    top: 20rem;
  }

  .E {
    left: 19rem;
    top: 20rem;
  }

  .F {
    left: 39rem;
    top: 12rem;
  }

  .G {
    left: 8rem;
    top: 36rem;
  }

  .H {
    left: 28rem;
    top: 36rem;
  }

  .I {
    left: 50rem;
    top: 20rem;
  }

  .J {
    left: 36rem;
    top: 40rem;
  }

  .K {
    left: 52rem;
    top: 40rem;
  }

  .L {
    left: 65rem;
    top: 22rem;
  }
`;






export const MainBanner = styled.div`
height: 100dvh;

background: url(${Banner});
background-repeat: no-repeat;
background-size: cover;



@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}



.header {
   background: linear-gradient(to bottom, #214761 60%, transparent 100%);
   display: flex;
   align-items: center;
   justify-content: space-between;
   animation: fadeIn 2s ease-in-out;

   padding: 2rem 6rem;

   .logo {
    width: clamp(17rem, 18vw, 38rem);
   }
}

@media (max-width: 600px) {
 .header {
    padding: 2rem 1rem;
 }
}
`;

export const MainGrid = styled(Grid)`
animation: fadeIn 2s ease-in-out;

.sideA {
    padding-top: clamp(2rem, 1vw, 4rem);
    padding-left: 10rem;

    h1 {
        font-size: clamp(7rem, 8vw, 20rem);
        font-family: 'Baloo';
        line-height: clamp(9rem, 8vw, 16rem);

        margin-left: -1rem;
        letter-spacing: 4px;
        color: ${({ theme }) => theme.COLORS.CYAN};
    }

    h2 {
        font-size: clamp(4rem, 3vw, 6.5rem);
        font-family: 'Mont-Bold';
        color: ${({ theme }) => theme.COLORS.WHITE};
    }

    h3 {
        font-family: 'Mont-Bold';
        font-size: clamp(3rem, 3vw, 5rem);
        color: ${({ theme }) => theme.COLORS.CYAN};
        margin-top: 2rem;
    }

    h4 {
        font-family: 'Mont-Light';
        font-size: clamp(2.4rem, 4vw, 4rem);
        margin-bottom: 3rem;
        color: ${({ theme }) => theme.COLORS.WHITE};
    }


    a {
    padding: 1.3rem 6% ;
    height: 5rem;
    border-radius: 999px;
    
    
    background-color:${({ theme }) => theme.COLORS.CYAN};
    color: ${({ theme }) => theme.COLORS.WHITE};

    font-size: clamp(1.4rem, 2vw, 3rem);
    font-family: 'Mont-Bold';
    letter-spacing: 2px;
    text-decoration: none;
    transition: all .1s;
    
    &:hover {
        background-color:${({ theme }) => theme.COLORS.BLUE};
    }
    }

}

.sideB {
    padding-top: 4rem;

    img {
        width: 85%;
        float: right;
    }
}


@media (max-width: 900px) {
    .sideB {
    display: none;
}

   .sideA {
     text-align: center;
     padding-left: 2rem;
     padding-right: 2rem;

     padding-top: 6rem;

   }
}
`;


export const UltragraphGrid = styled(Grid)`
padding: 0 6rem;
letter-spacing: 1px;


.sideA {
    padding-top: 4rem;
    img {
        width: clamp(40rem, 35vw, 70rem);
    }
}

.sideB {
    padding-top: 10rem;

    h1 {
        font-size: clamp(4.2rem, 4vw, 6rem);
        font-family: 'Baloo';
        line-height: 5rem;

        margin-bottom: 2rem;
        letter-spacing: 4px;
        color: ${({ theme }) => theme.COLORS.CYAN};
    }


    p {
        font-family: 'Mont-Light';
        font-size: clamp(2.4rem, 4vw, 3rem);
    }
}


@media (max-width: 900px) {
    padding: 0 2rem;
    .sideA {
    display: none;
}

   .sideB {
     text-align: center;
   }
}
`;



export const VergalhoesGrid = styled(Grid)`
background-color: ${({ theme }) => theme.COLORS.GREY};
margin-top: 6rem;
padding-bottom: 10rem;
letter-spacing: 1px;

.sideA {
    padding-right: 6rem;
    padding-left: 10rem;
    padding-top: 6rem;

    h1 {
        font-size: clamp(4.2rem, 4vw, 6rem);
        font-family: 'Baloo';
        line-height: 5rem;

        margin-bottom: 2rem;
        letter-spacing: 4px;
        color: ${({ theme }) => theme.COLORS.CYAN};
    }

    p {
        font-family: 'Mont-Light';
        font-size: clamp(2.4rem, 4vw, 2.8rem);
    }
}


.sideB {
  position: relative;
}


.sideC {
    margin-top: 4rem;
    padding: 0 10rem;
    p {
        font-family: 'Mont-Light';
        font-size: clamp(2.4rem, 4vw, 2.8rem);
    }
}



@media (max-width: 900px) {
   text-align: center;

    .sideA {
    padding-right: 2rem;
    padding-left:  2rem;
    padding-top: 6rem;
}

   .sideB {
    display: none;
   }

   .sideC {
    padding: 0 2rem;
   }
}
`;


export const FormBanner = styled.div`
padding: 4rem 1rem;
display: flex;
flex-direction: column;
justify-content: center !important;

.form-text {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3rem;

    h1 {
        font-size: clamp(4.2rem, 5vw, 6rem);
        font-family: 'Baloo';
        line-height: 5rem;

        margin-bottom: 2rem;
        letter-spacing: 4px;
        color: ${({ theme }) => theme.COLORS.CYAN};
    }

    p {
        font-family: 'Mont-Bold';
        font-size: clamp(2.4rem, 4vw, 3rem);
    }
}


@media (max-width: 900px) {
    text-align: center;
}
`;

